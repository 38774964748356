.form__container {
  margin: 0 auto;
  max-width: 1160px;
  padding: 100px 120px 84px 120px;
  background: linear-gradient(
      270deg,
      rgba(51, 146, 233, 0.5) 0%,
      rgba(111, 204, 245, 0.5) 88.7%
    ),
    #6fccf5;
  box-shadow: 0px 0px 50px rgba(61, 70, 98, 0.3);
  -webkit-box-shadow: 0px 0px 50px rgba(61, 70, 98, 0.3);
  -moz-box-shadow: 0px 0px 50px rgba(61, 70, 98, 0.3);
  border-radius: 50px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -o-border-radius: 50px;
}
.form__smeta {
  background-image: url(../../images/form1.png);
  background-image: image-set(
    url(../../images/form1.png) type("image/png") 1x,
    url(../../images/form1load.png) type("image/png") 2x,
    url(../../images/form1.avif) type("image/avif") 1x,
    url(../../images/form1load.avif) type("image/avif") 2x,
    url(../../images/form1.webp) type("image/webp") 1x,
    url(../../images/form1load.webp) type("image/webp") 2x
  );
  background-image: -webkit-image-set(
    url(../../images/form1.png) 1x,
    url(../../images/form1load.png) 2x,
    url(../../images/form1.avif) 1x,
    url(../../images/form1load.avif) 2x,
    url(../../images/form1.webp) 1x,
    url(../../images/form1load.webp) 2x
  );
}
.form__zamer {
  padding: 100px 120px 65px 120px;
  background-image: url(../../images/form2.png);
  background-image: image-set(
    url(../../images/form2.png) type("image/png") 1x,
    url(../../images/form2load.png) type("image/png") 2x,
    url(../../images/form2.avif) type("image/avif") 1x,
    url(../../images/form2load.avif) type("image/avif") 2x,
    url(../../images/form2.webp) type("image/webp") 1x,
    url(../../images/form2load.webp) type("image/webp") 2x
  );
  background-image: -webkit-image-set(
    url(../../images/form2.png) 1x,
    url(../../images/form2load.png) 2x,
    url(../../images/form2.avif) 1x,
    url(../../images/form2load.avif) 2x,
    url(../../images/form2.webp) 1x,
    url(../../images/form2load.webp) 2x
  );
}
.form__zamer_article {
  margin: 50px 0;
  padding: 40px 50px;
}
.form__cooperation {
  padding: 70px 120px 47px 120px;
  background-image: url(../../images/form3.png);
  background-image: image-set(
    url(../../images/form3.png) type("image/png") 1x,
    url(../../images/form3load.png) type("image/png") 2x,
    url(../../images/form3.avif) type("image/avif") 1x,
    url(../../images/form3load.avif) type("image/avif") 2x,
    url(../../images/form3.webp) type("image/webp") 1x,
    url(../../images/form3load.webp) type("image/webp") 2x
  );
  background-image: -webkit-image-set(
    url(../../images/form3.png) 1x,
    url(../../images/form3load.png) 2x,
    url(../../images/form3.avif) 1x,
    url(../../images/form3load.avif) 2x,
    url(../../images/form3.webp) 1x,
    url(../../images/form3load.webp) 2x
  );
}
.form__popup-zayavka {
  padding: 100px 320px 84px 120px;
  background-image: url(../../images/popup1.png);
  background-image: image-set(
    url(../../images/popup1.png) type("image/png") 1x,
    url(../../images/popup1load.png) type("image/png") 2x,
    url(../../images/popup1.avif) type("image/avif") 1x,
    url(../../images/popup1load.avif) type("image/avif") 2x,
    url(../../images/popup1.webp) type("image/webp") 1x,
    url(../../images/popup1load.webp) type("image/webp") 2x
  );
  background-image: -webkit-image-set(
    url(../../images/popup1.png) 1x,
    url(../../images/popup1load.png) 2x,
    url(../../images/popup1.avif) 1x,
    url(../../images/popup1load.avif) 2x,
    url(../../images/popup1.webp) 1x,
    url(../../images/popup1load.webp) 2x
  );
}
.form__popup-question {
  padding: 100px 605px 65px 120px;
  background-image: url(../../images/popup2.png);
  background-image: image-set(
    url(../../images/popup2.png) type("image/png") 1x,
    url(../../images/popup2load.png) type("image/png") 2x,
    url(../../images/popup2.avif) type("image/avif") 1x,
    url(../../images/popup2load.avif) type("image/avif") 2x,
    url(../../images/popup2.webp) type("image/webp") 1x,
    url(../../images/popup2load.webp) type("image/webp") 2x
  );
  background-image: -webkit-image-set(
    url(../../images/popup2.png) 1x,
    url(../../images/popup2load.png) 2x,
    url(../../images/popup2.avif) 1x,
    url(../../images/popup2load.avif) 2x,
    url(../../images/popup2.webp) 1x,
    url(../../images/popup2load.webp) 2x
  );
}
.form {
  max-width: 962px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.form__popup-question .form {
  max-width: 675px;
}
.form__title {
  max-width: 742px;
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 1.5;
  color: #fff;
  text-align: left;
  margin: 0 0 20px 0;
}
.form__zamer .form__title {
  max-width: 822px;
  margin: 0 0 50px 0;
}
.form__subtitle {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  margin: 0 0 60px 0;
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}
.form__title_article {
  font-size: 25px;
  margin: 0 0 10px 0;
}
.form__subtitle_article {
  font-size: 20px;
  margin: 0 0 50px 0;
}
.form__text {
  max-width: 533px;
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: #fff;
  margin: 0 0 30px 0;
  text-align: left;
}
.form__cooperation .form__subtitle {
  margin: 0 0 10px 0;
}
.input__radio__label {
  width: 276px;
  margin: 0 0 30px 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.input__radio__label_calc {
  width: 88px;
  margin: 0;
}
.input__radio__label_calc_big {
  width: 133px;
  padding: 5px 20px;
  margin: 0 20px 0 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.input__radio {
  border: none;
  box-sizing: border-box;
  border-bottom: 1px solid #bcbcc4;
  background-color: transparent;
  margin-top: 5px;
  font-size: 14px;
  font-family: inherit;
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  -webkit-border: none;
  -moz-border: none;
  -o-border: none;
  -webkit-border-bottom: 1px solid #bcbcc4;
  -moz-border-bottom: 1px solid #bcbcc4;
  -o-border-bottom: 1px solid #bcbcc4;
}
.input__label_img {
  width: 135px;
  height: 135px;
  object-fit: cover;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
}
.input__radio:disabled + .input__label_img {
  border: none;
  -webkit-border: none;
  -moz-border: none;
  -o-border: none;
}
.input__radio:checked + .input__label_img {
  width: 125px;
  height: 125px;
  border: #6fccf5 solid 5px;
  -webkit-border: #6fccf5 solid 5px;
  -moz-border: #6fccf5 solid 5px;
  -o-border: #6fccf5 solid 5px;
  -webkit-box-shadow: 0px 0px 0px 1px #e7e7e7;
  -moz-box-shadow: 0px 0px 0px 1px #e7e7e7;
  box-shadow: 0px 0px 0px 1px #e7e7e7;
}
.input__check {
  display: block;
  min-width: 20px;
  min-height: 20px;
  background: #f1f6fa;
  border: 1px solid #e7e7e7;
  border-radius: 50%;
  -webkit-border: 1px solid #e7e7e7;
  -moz-border: 1px solid #e7e7e7;
  -o-border: 1px solid #e7e7e7;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
}
.input__radio:disabled + .input__check {
  border: none;
  -webkit-border: none;
  -moz-border: none;
  -o-border: none;
  background-color: #e8e8e8;
}
.input__radio:checked + .input__check {
  min-width: 10px;
  min-height: 10px;
  border: #fff solid 6px;
  -webkit-border: #fff solid 6px;
  -moz-border: #fff solid 6px;
  -o-border: #fff solid 6px;
  background: #6fccf5;
  background-size: 21px;
  -webkit-box-shadow: 0px 0px 0px 1px #e7e7e7;
  -moz-box-shadow: 0px 0px 0px 1px #e7e7e7;
  box-shadow: 0px 0px 0px 1px #e7e7e7;
}
.input__text {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  color: #3d4662;
  margin: 0 0 0px 20px;
  text-align: left;
}
.input__radio__label_calc_big .input__text {
  margin: 0;
}
.form__button {
  width: 560px;
  height: 80px;
  font-family: "GothamPro";
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.286;
  color: #ffffff;
  margin: 10px auto;
  text-align: left;
  background: linear-gradient(
      270deg,
      rgba(51, 146, 233, 0.5) 0%,
      rgba(111, 204, 245, 0.5) 88.7%
    ),
    #6fccf5;
  border: none;
  -webkit-border: none;
  -moz-border: none;
  -o-border: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
  -o-outline: none;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: opacity linear 1s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form__button_min {
  width: 390px;
  height: 80px;
  margin: 0 0 0 10px;
  color: #3d4662;
  background: linear-gradient(
      270deg,
      rgba(51, 146, 233, 0.5) 0%,
      rgba(111, 204, 245, 0.5) 88.7%
    ),
    linear-gradient(0deg, #ffffff, #ffffff), #6fccf5;
}
.form__button:hover {
  opacity: 0.5;
}
.form__popup-question .form__button {
  margin: 10px 0 0 10px;
}
.input__label {
  margin: 0 0 20px 0;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.input__label_param {
  width: 100%;
  margin: 0 0 10px 0;
}
.form__popup-question .input__label:nth-of-type(1) {
  order: -1;
}
.form__popup-question .input__label:nth-of-type(3) {
  order: -1;
}
.input__span {
  opacity: 0;
  position: absolute;
  top: 10px;
  left: 21px;
  font-family: "GothamPro";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.2;
  color: #3d4662;
  text-align: left;
  cursor: pointer;
}
.input__span_active {
  opacity: 1;
  transition: opacity linear 0.5s;
}
.input__span_name {
  top: 10px;
}
.input__span_metr {
  position: absolute;
  top: 16px;
  left: 47px;
  font-family: "GothamPro";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.2;
  color: #3d4662;
  text-align: left;
  cursor: pointer;
}
.input__title {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 1.2;
  color: #3d4662;
  text-align: left;
  cursor: pointer;
  margin: 0 0 10px 0;
}
.form__input {
  font-family: "GothamPro";
  font-weight: 400;
  line-height: 1.2;
  font-size: 12px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  border: none;
  -webkit-border: none;
  -moz-border: none;
  -o-border: none;
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
  -o-outline: none;
  cursor: pointer;
  width: 255px;
  height: 60px;
  margin: 0 10px 0 0;
  padding: 0 0 0 20px;
  background-color: #f1f6fa;
  -webkit-appearance: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
.input__range {
  -webkit-appearance: none;
  margin: 10px 0 20px 0;
  width: 100%;
  height: 7px;
  background: #f1f6fa;
  border-radius: 5px;
  background-image: linear-gradient(#6fccf5, #6fccf5);
  background-repeat: no-repeat;
  cursor: pointer;
  border: 1px solid #f1f6fa;
}

/* Input Thumb */
.input__range::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #6fccf5;
  border: #fff solid 6px;
  -webkit-border: #fff solid 6px;
  -moz-border: #fff solid 6px;
  -o-border: #fff solid 6px;
  box-shadow: 0px 0px 0px 1px #e7e7e7;
}
.input__range::-moz-range-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #6fccf5;
}

.input__range::-ms-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #6fccf5;
}
.input__range_metr {
  background-size: 32% 100%;
}
.input__range_floor {
  background-size: 19% 100%;
}
.input__range_kilometr {
  background-size: 10% 100%;
}
.form__input_min {
  height: 80px;
}
.form__input_question {
  width: 370px;
  height: 80px;
  margin: 0;
}
.form__input-name {
  margin: 0 0 10px 0;
  width: 540px;
}
.form__input_active {
  color: #3d4662;
  border: 2px solid rgb(111, 204, 245);
  -webkit-border: 2px solid rgb(111, 204, 245);
  -moz-border: 2px solid rgb(111, 204, 245);
  -o-border: 2px solid rgb(111, 204, 245);
  box-shadow: 0 0 0px 5px rgb(255, 255, 255) inset;
  -webkit-box-shadow: 0 0 0px 5px rgb(255, 255, 255) inset;
  -moz-box-shadow: 0 0 0px 5px rgb(255, 255, 255) inset;
  width: 251px;
  height: 56px;
}
.form__input_active_min {
  border: 2px solid #fff;
  -webkit-border: 2px solid #fff;
  -moz-border: 2px solid #fff;
  -o-border: 2px solid #fff;
  box-shadow: 0 0 0px 5px rgb(111, 204, 245) inset;
  -webkit-box-shadow: 0 0 0px 5px rgb(111, 204, 245) inset;
  -moz-box-shadow: 0 0 0px 5px rgb(111, 204, 245) inset;
  height: 76px;
}
.form__input_active_question {
  border: 2px solid #fff;
  -webkit-border: 2px solid #fff;
  -moz-border: 2px solid #fff;
  -o-border: 2px solid #fff;
  box-shadow: 0 0 0px 5px rgb(111, 204, 245) inset;
  -webkit-box-shadow: 0 0 0px 5px rgb(111, 204, 245) inset;
  -moz-box-shadow: 0 0 0px 5px rgb(111, 204, 245) inset;
  width: 366px;
  height: 76px;
}
.form__input_calculate-mini {
  width: 88px;
  height: 44px;
  padding: 0 5px 0 20px;
}
.form__input_active_calculate-mini {
  width: 84px;
  height: 40px;
}
.form__input-name_active {
  width: 536px;
}
.input__label:nth-of-type(2) .form__input {
  margin: 0;
}
.input__label:nth-of-type(2) .form__input_calculate-mini {
  margin: 0 10px 0 0;
}
.form__popup-question .input__label:nth-of-type(2) .form__input {
  margin: 10px 0 0 0;
}
.form__popup-question .input__label:nth-of-type(2) .input__span {
  top: 20px;
}
.form__input::-webkit-input-placeholder {
  opacity: 0.5;
}
.form__input::-moz-placeholder {
  opacity: 0.5;
}
.form__input:-moz-placeholder {
  opacity: 0.5;
}
.form__input:-ms-input-placeholder {
  opacity: 0.5;
}
.form__input:focus::-webkit-input-placeholder {
  opacity: 0;
}
.form__input:focus::-moz-placeholder {
  opacity: 0;
}
.form__input:focus:-moz-placeholder {
  opacity: 0;
}
.form__input:focus:-ms-input-placeholder {
  opacity: 0;
}
.form__select {
  font-family: "GothamPro";
  font-weight: 400;
  line-height: 1.2;
  font-size: 12px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  border: none;
  -webkit-border: none;
  -moz-border: none;
  -o-border: none;
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
  -o-outline: none;
  cursor: pointer;
  width: 275px;
  height: 60px;
  margin: 0 10px 0 0;
  padding: 0 0 0 20px;
  background-color: #f1f6fa;
  -webkit-appearance: none;
  background-image: url(../../images/icon/select.svg);
  background-position: 240px;
  background-repeat: no-repeat;
  background-size: 16px;
}
.form__select:nth-of-type(2) {
  margin: 0;
}
.form__select > option {
  font-family: "GothamPro";
  font-weight: 400;
  line-height: 1.2;
  font-size: 12px;
  border: none;
  -webkit-border: none;
  -moz-border: none;
  -o-border: none;
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
  -o-outline: none;
  cursor: pointer;
  background-color: #f1f6fa;
  -webkit-appearance: none;
}
.form__button_disabled {
  cursor: no-drop;
}
.form__policy {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: #3d4662;
  margin: 0;
  text-align: center;
  opacity: 0.5;
}
.form__policy_min {
  max-width: 235px;
  color: #ffffff;
  opacity: 1;
  margin: 5px auto 0 auto;
}
.form__policy_span {
  cursor: pointer;
  color: #6fccf5;
  opacity: 1;
  transition: opacity linear 1s;
}
.form__policy_span_min {
  color: #ffffff;
  font-weight: 500;
}
.form__policy_span:hover {
  opacity: 0.5;
}
.form__file-box {
  position: relative;
  order: 1;
  margin: -21px 0 0 0;
}
.form__input-form {
  opacity: 0;
  position: absolute;
  width: 60px;
  height: 60px;
}
.input-form__label {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: #fff;
  text-align: left;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
  -o-outline: none;
  cursor: pointer;
}
.input-form__label_span {
  border-bottom: 1px dashed #ffffff;
  -webkit-border-bottom: 1px dashed #ffffff;
  -moz-border-bottom: 1px dashed #ffffff;
  -o-border-bottom: 1px dashed #ffffff;
}
.input-form__label::before {
  content: "";
  display: block;
  width: 60px;
  height: 60px;
  background-image: url(../../images/icon/file.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 20px 0 0;
}
@media screen and (max-width: 1399px) {
  .form__popup-zayavka {
    padding: 100px 120px 84px 120px;
  }

  .form__popup-question {
    padding: 100px 405px 65px 120px;
  }
}
@media screen and (max-width: 1200px) {
  .form__container {
    padding: 40px 24px 40px 24px;
  }
  .form__popup-question {
    padding: 40px 205px 40px 120px;
  }
}
@media screen and (max-width: 1055px) {
  .form__title {
    max-width: 619px;
    font-size: 30px;
  }
  .form__subtitle {
    margin: 0 0 30px 0;
  }
  .form__input_min {
    width: 252px;
    height: 60px;
  }
  .form__input_question {
    width: 252px;
    height: 60px;
    margin: 0;
  }
  .form__input_active_min {
    width: 248px;
    height: 56px;
  }
  .form__button_min {
    width: 272px;
    height: 60px;
  }
}
@media screen and (max-width: 1024px) {
  .form__title_article {
    font-size: 18px;
  }
  .form__subtitle_article {
    font-size: 15px;
  }
}
@media screen and (max-width: 931px) {
  .form__container {
    background-position: 74%;
  }
  .form__popup-question {
    padding: 40px 24px 40px 24px;
  }
  .form {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .form__title {
    text-align: center;
    margin: 0 auto 20px auto;
  }
  .form__zamer .form__title {
    margin: 0 auto 50px auto;
  }
  .form__subtitle {
    max-width: 467px;
    text-align: center;
    margin: 0 auto 30px auto;
  }
  .form__cooperation .form__subtitle {
    margin: 0 auto 20px auto;
  }
  .form__text {
    text-align: center;
    margin: 0 auto 30px auto;
  }
  .form__input_min {
    margin: 0 0 10px 0;
  }
  .form__button_min {
    margin: 0;
  }
  .form__file-box {
    max-width: 196px;
    margin: 10px 0 26px 0;
    order: 0;
  }
  .input-form__label {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .input-form__label_span {
    border: none;
    -webkit-border: none;
    -moz-border: none;
    -o-border: none;
  }
  .input-form__label::before {
    margin: 0 0 20px 0;
  }
  .form__popup-question .input__label:nth-of-type(3) {
    order: 0;
  }
  .form__popup-question .input__label:nth-of-type(3) .form__input {
    margin: 20px 0 0 0;
    width: 252px;
    height: 140px;
  }
}
@media screen and (max-width: 712px) {
  .form__popup-zayavka {
    background-image: url(../../images/popupmob.png);
    background-image: image-set(
      url(../../images/popupmob.png) type("image/png") 1x,
      url(../../images/popupmobload.png) type("image/png") 2x,
      url(../../images/popupmob.avif) type("image/avif") 1x,
      url(../../images/popupmobload.avif) type("image/avif") 2x,
      url(../../images/popupmob.webp) type("image/webp") 1x,
      url(../../images/popupmobload.webp) type("image/webp") 2x
    );
    background-image: -webkit-image-set(
      url(../../images/popupmob.png) 1x,
      url(../../images/popupmobload.png) 2x,
      url(../../images/popupmob.avif) 1x,
      url(../../images/popupmobload.avif) 2x,
      url(../../images/popupmob.webp) 1x,
      url(../../images/popupmobload.webp) 2x
    );
  }
  .form__popup-question {
    background-image: url(../../images/popupmob.png);
    background-image: image-set(
      url(../../images/popupmob.png) type("image/png") 1x,
      url(../../images/popupmobload.png) type("image/png") 2x,
      url(../../images/popupmob.avif) type("image/avif") 1x,
      url(../../images/popupmobload.avif) type("image/avif") 2x,
      url(../../images/popupmob.webp) type("image/webp") 1x,
      url(../../images/popupmobload.webp) type("image/webp") 2x
    );

    background-image: -webkit-image-set(
      url(../../images/popupmob.png) 1x,
      url(../../images/popupmobload.png) 2x,
      url(../../images/popupmob.avif) 1x,
      url(../../images/popupmobload.avif) 2x,
      url(../../images/popupmob.webp) 1x,
      url(../../images/popupmobload.webp) 2x
    );
  }
  .form__popup-zayavka .form {
    margin: 0 auto 0 auto;
  }
  .form__popup-question .form {
    margin: 0 auto 0 auto;
  }
  .form__title {
    max-width: 272px;
    font-size: 24px;
  }
  .form__subtitle {
    max-width: 272px;
  }
  .form__input-name {
    width: 255px;
  }
  .form__input-name_active {
    width: 251px;
  }
  .form__select {
    margin: 0 0 10px 0;
  }
  .form__radio {
    margin: 16px 0px 0px 0;
  }
  .form__radio-label {
    font-size: 12px;
    margin: 16px 0 0px 10px;
  }
  .form__radio-label:nth-of-type(1) {
    margin: 16px 20px 0px 10px;
  }
  .form__button {
    width: 272px;
    height: 60px;
    padding: 12px 5px;
    margin: 10px auto;
  }
  .calculate-mini_popup .form__input_active {
    border: 2px solid #fff;
    -webkit-border: 2px solid #fff;
    -moz-border: 2px solid #fff;
    -o-border: 2px solid #fff;
    box-shadow: 0 0 0px 5px rgb(111, 204, 245) inset;
    -webkit-box-shadow: 0 0 0px 5px rgb(111, 204, 245) inset;
    -moz-box-shadow: 0 0 0px 5px rgb(111, 204, 245) inset;
  }
  .input__radio__label_calc {
    margin: 0 0 10px 0;
  }
}
@media screen and (max-width: 425px) {
  .form__smeta {
    background-image: url(../../images/form1mob.png);
    background-image: image-set(
      url(../../images/form1mob.png) type("image/png") 1x,
      url(../../images/form1mobload.png) type("image/png") 2x,
      url(../../images/form1mob.avif) type("image/avif") 1x,
      url(../../images/form1mobload.avif) type("image/avif") 2x,
      url(../../images/form1mob.webp) type("image/webp") 1x,
      url(../../images/form1mobload.webp) type("image/webp") 2x
    );
    background-image: -webkit-image-set(
      url(../../images/form1mob.png) 1x,
      url(../../images/form1mobload.png) 2x,
      url(../../images/form1mob.avif) 1x,
      url(../../images/form1mobload.avif) 2x,
      url(../../images/form1mob.webp) 1x,
      url(../../images/form1mobload.webp) 2x
    );
  }
  .form__zamer {
    background-image: url(../../images/form2mob.png);
    background-image: image-set(
      url(../../images/form2mob.png) type("image/png") 1x,
      url(../../images/form2mobload.png) type("image/png") 2x,
      url(../../images/form2mob.avif) type("image/avif") 1x,
      url(../../images/form2mobload.avif) type("image/avif") 2x,
      url(../../images/form2mob.webp) type("image/webp") 1x,
      url(../../images/form2mobload.webp) type("image/webp") 2x
    );
    background-image: -webkit-image-set(
      url(../../images/form2mob.png) 1x,
      url(../../images/form2mobload.png) 2x,
      url(../../images/form2mob.avif) 1x,
      url(../../images/form2mobload.avif) 2x,
      url(../../images/form2mob.webp) 1x,
      url(../../images/form2mobload.webp) 2x
    );
  }
  .form__cooperation {
    background-image: url(../../images/form3mob.png);
    background-image: image-set(
      url(../../images/form3mob.png) type("image/png") 1x,
      url(../../images/form3mobload.png) type("image/png") 2x,
      url(../../images/form3mob.avif) type("image/avif") 1x,
      url(../../images/form3mobload.avif) type("image/avif") 2x,
      url(../../images/form3mob.webp) type("image/webp") 1x,
      url(../../images/form3mobload.webp) type("image/webp") 2x
    );
    background-image: -webkit-image-set(
      url(../../images/form3mob.png) 1x,
      url(../../images/form3mobload.png) 2x,
      url(../../images/form3mob.avif) 1x,
      url(../../images/form3mobload.avif) 2x,
      url(../../images/form3mob.webp) 1x,
      url(../../images/form3mobload.webp) 2x
    );
  }
  .form {
    margin: 156px auto 0 auto;
  }
  .form__title {
    font-size: 18px;
  }
  .form__smeta .form__subtitle {
    display: none;
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}
