.main {
  position: relative;
  z-index: 0;
  background-color: rgba(241, 246, 250, 1);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  background-image: url("../../images/main.png");
  background-image: image-set(
    url(../../images/main.png) type("image/png") 1x,
    url(../../images/mainload.png) type("image/png") 2x,
    url(../../images/main.avif) type("image/avif") 1x,
    url(../../images/mainload.avif) type("image/avif") 2x,
    url(../../images/main.webp) type("image/webp") 1x,
    url(../../images/mainload.webp) type("image/webp") 2x
  );
  background-image: -webkit-image-set(
    url(../../images/main.png) 1x,
    url(../../images/mainload.png) 2x,
    url(../../images/main.avif) 1x,
    url(../../images/mainload.avif) 2x,
    url(../../images/main.webp) 1x,
    url(../../images/mainload.webp) 2x
  );
  background-position: center 135px;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 138px 0 0 0;
  transition: background-image linear 2s;
}
.main__container {
  position: relative;
  margin: 0 auto;
  width: 1166px;
  padding: 0 24px;
}
.main__box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 111px 0 131px 0;
}
.main__title {
  max-width: 520px;
  font-family: "GothamPro";
  font-style: normal;
  font-size: 48px;
  font-weight: 500;
  line-height: 1.5;
  color: #3d4662;
  margin: 0 0 40px 0;
  text-align: left;
}
.main__title-otoplenie {
  max-width: 851px;
}
.main__title_span {
  color: #6fccf5;
}
.main__subtitle {
  max-width: 438px;
  font-family: "GothamPro";
  font-style: normal;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  color: #3d4662;
  margin: 0;
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}
.main__subtitle_span {
  font-weight: 400;
  text-transform: uppercase;
}
.main__subtitle::before {
  content: "";
  display: block;
  min-width: 48px;
  height: 10px;
  background-image: url(../../images/icon/tochka.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 41px 0 0;
}
.main__button {
  width: 360px;
  height: 80px;
  font-family: "GothamPro";
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  padding: 21px 41px;
  margin: 72px 0 0 0;
  text-align: left;
  background: linear-gradient(
      270deg,
      rgba(51, 146, 233, 0.5) 0%,
      rgba(111, 204, 245, 0.5) 88.7%
    ),
    #6fccf5;
  border: none;
  -webkit-border: none;
  -moz-border: none;
  -o-border: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
  -o-outline: none;
  cursor: pointer;
  text-align: center;
  letter-spacing: 0.1em;
  transition: opacity linear 1s;
}
.main__button:hover {
  opacity: 0.3;
}
.main__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 278px;
  height: 38px;
  font-family: "GothamPro";
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  padding: 21px 41px;
  margin: 72px 0 0 0;
  text-align: left;
  background: linear-gradient(
      270deg,
      rgba(51, 146, 233, 0.5) 0%,
      rgba(111, 204, 245, 0.5) 88.7%
    ),
    #6fccf5;
  border: none;
  -webkit-border: none;
  -moz-border: none;
  -o-border: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
  -o-outline: none;
  cursor: pointer;
  text-align: center;
  letter-spacing: 0.1em;
  transition: opacity linear 1s;
  text-decoration: none;
  text-transform: uppercase;
}
.main__link:hover {
  opacity: 0.3;
}
@media screen and (max-width: 1066px) {
  .main__box {
    margin: 50px 0 50px 24px;
  }
}
@media screen and (max-width: 950px) {
  .main__title {
    max-width: 425px;
    font-size: 35px;
  }
  .main__title-otoplenie {
    max-width: 620px;
  }
  .main__subtitle {
    max-width: 347px;
    font-size: 18px;
  }
  .main__subtitle::before {
    margin: 0 20px 0 0;
  }
  .main__button {
    width: 300px;
    height: 60px;
    font-size: 12px;
    padding: 21px 17px;
  }
  .main__link {
    width: 266px;
    height: 18px;
    font-size: 12px;
    padding: 21px 17px;
  }
}
@media screen and (max-width: 768px) {
  .main {
    padding: 94px 0 0 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: center 94px;
    background-image: url(../../images/mainmob.png);
    background-image: image-set(
      url(../../images/mainmob.png) type("image/png") 1x,
      url(../../images/mainmobload.png) type("image/png") 2x,
      url(../../images/mainmob.avif) type("image/avif") 1x,
      url(../../images/mainmobload.avif) type("image/avif") 2x,
      url(../../images/mainmob.webp) type("image/webp") 1x,
      url(../../images/mainmobload.webp) type("image/webp") 2x
    );
    background-image: -webkit-image-set(
      url(../../images/mainmob.png) 1x,
      url(../../images/mainmobload.png) 2x,
      url(../../images/mainmob.avif) 1x,
      url(../../images/mainmobload.avif) 2x,
      url(../../images/mainmob.webp) 1x,
      url(../../images/mainmobload.webp) 2x
    );
  }
  .main__container {
    width: 100%;
    padding: 0;
  }
  .main__box {
    justify-content: center;
    align-items: center;
    margin: 40px 24px 77px 24px;
  }
  .main__title {
    max-width: 260px;
    font-size: 24px;
    margin: 0 0 30px 0;
    text-align: center;
  }
  .main__title-otoplenie {
    max-width: 426px;
  }
  .main__subtitle {
    max-width: 305px;
    font-size: 16px;
  }
  .main__button {
    width: 272px;
    margin: 93px 0 0 0;
    padding: 11px 17px;
  }
  .main__link {
    width: 238px;
    margin: 93px 0 0 0;
    padding: 11px 17px;
  }
}
