.article {
  max-width: 1156px;
  margin: 0 auto;
  padding: 100px 24px 0 24px;
}
.article__all {
  padding: 200px 24px 0 24px;
}
.article__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.article__box {
  max-width: 237px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 10px;
  border: 1px solid #eaeaea;
  -webkit-border: 1px solid #eaeaea;
  -moz-border: 1px solid #eaeaea;
  -o-border: 1px solid #eaeaea;
  margin: 0 40px 20px 0;
}
.article__box:nth-of-type(4) {
  margin: 0 0 20px 0;
}
.article__box:nth-of-type(8) {
  margin: 0 0 20px 0;
}
.article__img {
  width: 238px;
  height: 152px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  object-fit: cover;
}
.article__title {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2;
  color: #3d4662;
  text-align: justify;
  max-width: 238px;
  margin: 10px 0 0 0;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.article__button {
  width: 237px;
  height: 50px;
  font-family: "GothamPro";
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.286;
  color: #ffffff;
  margin: 10px auto auto auto;
  text-align: left;
  background: linear-gradient(
      270deg,
      rgba(51, 146, 233, 0.5) 0%,
      rgba(111, 204, 245, 0.5) 88.7%
    ),
    #6fccf5;
  border: none;
  -webkit-border: none;
  -moz-border: none;
  -o-border: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
  -o-outline: none;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: opacity linear 1s;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1207px) {
  .article__box {
    margin: 0 20px 20px 0;
  }
  .article__box:nth-of-type(2n) {
    margin: 0 0 20px 0;
  }
}
@media screen and (max-device-width: 768px) {
  .article__box:nth-of-type(2n) {
    margin: 0 20px 20px 0;
  }
}
@media screen and (max-width: 579px) {
  .article__box {
    max-width: 178px;
  }
  .article__img {
    width: 178px;
    height: 114px;
  }
  .article__title {
    font-size: 12px;
    max-width: 178px;
    height: 95px;
  }
  .article__button {
    width: 178px;
    height: 40px;
  }
}
@media screen and (max-width: 487px) {
  .article__container {
    max-width: 200px;
    margin: 0 auto;
  }
  .article__box {
    margin: 0 0 16px 0;
  }
  .article__box:nth-of-type(2n) {
    margin: 0 0 16px 0;
  }
}
