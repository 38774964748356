@font-face {
  font-family: "GothamPro";
  src: url("./fonts/GothamPro.eot") format("eot"),
    url("./fonts/GothamPro.otf") format("opentype"),
    url("./fonts/GothamPro.woff") format("woff"),
    url("./fonts/GothamPro.ttf") format("truetype"),
    url("./fonts/GothamPro.svg#GothamPro") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GothamPro";
  src: url("./fonts/GothamPro-Black.eot") format("eot"),
    url("./fonts/GothamPro-Black.otf") format("opentype"),
    url("./fonts/GothamPro-Black.woff") format("woff"),
    url("./fonts/GothamPro-Black.ttf") format("truetype"),
    url("./fonts/GothamPro-Black.svg#GothamPro-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GothamPro";
  src: url("./fonts/GothamPro-BlackItalic.eot") format("eot"),
    url("./fonts/GothamPro-BlackItalic.otf") format("opentype"),
    url("./fonts/GothamPro-BlackItalic.woff") format("woff"),
    url("./fonts/GothamPro-BlackItalic.ttf") format("truetype"),
    url("./fonts/GothamPro-BlackItalic.svg#GothamPro-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "GothamPro";
  src: url("./fonts/GothamPro-Bold.eot") format("eot"),
    url("./fonts/GothamPro-Bold.otf") format("opentype"),
    url("./fonts/GothamPro-Bold.woff") format("woff"),
    url("./fonts/GothamPro-Bold.ttf") format("truetype"),
    url("./fonts/GothamPro-Bold.svg#GothamPro-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GothamPro";
  src: url("./fonts/GothamPro-BoldItalic.eot") format("eot"),
    url("./fonts/GothamPro-BoldItalic.otf") format("opentype"),
    url("./fonts/GothamPro-BoldItalic.woff") format("woff"),
    url("./fonts/GothamPro-BoldItalic.ttf") format("truetype"),
    url("./fonts/GothamPro-BoldItalic.svg#GothamPro-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "GothamPro";
  src: url("./fonts/GothamPro-Italic.eot") format("eot"),
    url("./fonts/GothamPro-Italic.otf") format("opentype"),
    url("./fonts/GothamPro-Italic.woff") format("woff"),
    url("./fonts/GothamPro-Italic.ttf") format("truetype"),
    url("./fonts/GothamPro-Italic.svg#GothamPro-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "GothamPro";
  src: url("./fonts/GothamPro-Light.eot") format("eot"),
    url("./fonts/GothamPro-Light.otf") format("opentype"),
    url("./fonts/GothamPro-Light.woff") format("woff"),
    url("./fonts/GothamPro-Light.ttf") format("truetype"),
    url("./fonts/GothamPro-Light.svg#GothamPro-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GothamPro";
  src: url("./fonts/GothamPro-LightItalic.eot") format("eot"),
    url("./fonts/GothamPro-LightItalic.otf") format("opentype"),
    url("./fonts/GothamPro-LightItalic.woff") format("woff"),
    url("./fonts/GothamPro-LightItalic.ttf") format("truetype"),
    url("./fonts/GothamPro-LightItalic.svg#GothamPro-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "GothamPro";
  src: url("./fonts/GothamPro-Medium.eot") format("eot"),
    url("./fonts/GothamPro-Medium.otf") format("opentype"),
    url("./fonts/GothamPro-Medium.woff") format("woff"),
    url("./fonts/GothamPro-Medium.ttf") format("truetype"),
    url("./fonts/GothamPro-Medium.svg#GothamPro-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GothamPro";
  src: url("./fonts/GothamPro-MediumItalic.eot") format("eot"),
    url("./fonts/GothamPro-MediumItalic.otf") format("opentype"),
    url("./fonts/GothamPro-MediumItalic.woff") format("woff"),
    url("./fonts/GothamPro-MediumItalic.ttf") format("truetype"),
    url("./fonts/GothamPro-MediumItalic.svg#GothamPro-MediumItalic")
      format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

body {
  max-width: 100%;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  width: 10px; /* ширина scrollbar */
}
body::-webkit-scrollbar-track {
  display: none;
  opacity: 0; /* цвет дорожки */
}
body::-webkit-scrollbar-thumb {
  background-color: #6fccf5; /* цвет плашки */
  border-radius: 20px; /* закругления плашки */
  border: 3px solid #fff; /* padding вокруг плашки */
}
