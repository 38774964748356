.section__title {
  max-width: 1083px;
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 1.5;
  color: #3d4662;
  text-align: center;
  margin: 0 auto 20px auto;
}
.section__subtitle {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  text-transform: uppercase;
  color: #6fccf5;
  text-align: center;
  margin: 0 auto 20px auto;
}
.section__text {
  position: relative;
  z-index: 1;
  max-width: 808px;
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.5;
  color: #3d4662;
  text-align: center;
  margin: 0 auto 70px auto;
  opacity: 0.5;
}
.section__logo {
  width: 204px;
  height: 41px;
  margin: 0 auto 40px auto;
}
.calculate {
  padding: 140px 24px 0 24px;
}
#sw-app-ddccce15bcb1786ea5b53ff8d19b3767 .sw-review-bottom a {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10000000;
}
@media screen and (max-width: 768px) {
  .calculate {
    padding: 140px 0 0 0;
  }
  .section__title {
    max-width: 568px;
    font-size: 24px;
  }
  .section__subtitle {
    max-width: 568px;
    font-size: 18px;
  }
  .section__text {
    max-width: 568px;
    font-size: 18px;
  }
}
@media screen and (max-width: 500px) {
  .section__title {
    max-width: 252px;
  }
}
