.thanks__box {
  padding: 178px 0 50px 0;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  margin: auto;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  background-image: url(../../images/thanks.png);
}
.thanks__close {
  background: none;
  outline: none;
  border: none;
  background-image: url(../../images/icon/close.svg);
  width: 24px;
  height: 24px;
  background-size: 100%;
  justify-self: right;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  padding: 0;
}
.thanks__img {
  width: 150px;
  height: 150px;
}
.thanks__title {
  max-width: 480px;
  font-family: "GothamPro";
  font-size: 35px;
  font-weight: 900;
  line-height: 1.2;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
}
.thanks__span {
  color: #000000;
}
.thanks__text {
  max-width: 500px;
  font-family: "GothamPro";
  font-weight: 700;
  font-size: 15px;
  line-height: 1.2;
  text-align: center;
  color: #000000;
  text-transform: uppercase;
}
.thanks__box-mess {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.thanks__social-link {
  margin: 0 5px;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  transition: opacity linear 1s;
}
.thanks__social-link:nth-of-type(1) {
  background-image: url(../../images/icon/phone.svg);
  line-height: 0;
  font-size: 0;
  color: transparent;
}
.thanks__social-link:nth-of-type(2) {
  background-image: url(../../images/icon/whatsapp.svg);
}
.thanks__social-link:nth-of-type(3) {
  background-image: url(../../images/icon/viber.svg);
}
.thanks__social-link:nth-of-type(4) {
  background-image: url(../../images/icon/telega.svg);
}
.thanks__social-link:nth-of-type(2):hover {
  opacity: 0.5;
}
.thanks__social-link:nth-of-type(3):hover {
  opacity: 0.5;
}
.thanks__social-link:nth-of-type(4):hover {
  opacity: 0.5;
}
@media screen and (max-width: 990px) {
  .thanks__box {
    padding: 200px 0 50px 0;
  }
}
@media screen and (max-width: 790px) {
  .thanks__box {
    padding: 130px 0 50px 0;
  }
}
@media screen and (max-width: 500px) {
  .thanks__img {
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }
  .thanks__title {
    max-width: 246px;
    font-size: 19px;
  }
  .thanks__text {
    max-width: 360px;
    font-size: 11px;
  }
}
