.header {
  position: fixed;
  border-bottom: 1px solid #e7e7e7;
  -webkit-border-bottom: 1px solid #e7e7e7;
  -moz-border-bottom: 1px solid #e7e7e7;
  -o-border-bottom: 1px solid #e7e7e7;
  background-color: #f1f6fa;
  padding: 16px 0 0 0;
  z-index: 5;
  width: 100%;
}
.header__box {
  max-width: 1159px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 0 auto;
  padding: 0 24px 0 24px;
}
.header__logo-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.header__logo-link {
  text-decoration: none;
  outline: none;
  margin: 0;
  padding: 0;
}
.header__logo {
  width: 201px;
  height: 41px;
  margin: 0 20px 0 0;
}
.header__logo-text {
  max-width: 215px;
  font-family: "GothamPro";
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #040608;
  opacity: 0.5;
  text-align: left;
}
.header__raiting {
  width: 100px;
  margin: 0 10px 0 0;
}
.header__adress {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  max-width: 206px;
  font-family: "GothamPro";
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #040608;
  text-align: left;
  margin: 0;
}
.header__adress::before {
  content: "";
  display: block;
  min-width: 20px;
  height: 25px;
  background-image: url(../../images/icon/map.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 10px 0 0;
}
.header__mail-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
}
.header__mail {
  font-family: "GothamPro";
  font-style: normal;
  font-size: 15px;
  font-weight: 400;
  line-height: 0.9;
  color: #3d4662;
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
  -o-outline: none;
  text-decoration: none;
  text-align: left;
  transition: opacity linear 1s;
  text-align: center;
  margin: 5px auto 0 auto;
}
.header__mail:hover {
  opacity: 0.3;
}
.header__tel-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
}
.header__text {
  max-width: 116px;
  font-family: "GothamPro";
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #040608;
  opacity: 0.5;
  margin: 0 0 5px 0;
}
.header__tel {
  max-width: 222px;
  font-family: "GothamPro";
  font-style: normal;
  font-size: 20px;
  font-weight: 500;
  line-height: 0.9;
  color: #3d4662;
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
  -o-outline: none;
  text-decoration: none;
  text-transform: uppercase;
  text-align: left;
  transition: opacity linear 1s;
}
.header__tel:hover {
  opacity: 0.3;
}
.header__tel-button {
  max-width: 148px;
  height: 38px;
  font-family: "GothamPro";
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  color: #6fccf5;
  margin: 0;
  padding: 0;
  background: none;
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
  -o-outline: none;
  border: none;
  -webkit-border: none;
  -moz-border: none;
  -o-border: none;
  text-transform: uppercase;
  text-align: left;
  border-bottom: 4px solid #6fccf5;

  -webkit-border-bottom: 4px solid #6fccf5;
  -moz-border-bottom: 4px solid #6fccf5;
  -o-border-bottom: 4px solid #6fccf5;
  cursor: pointer;
  transition: opacity linear 1s;
}
.header__tel-button:hover {
  opacity: 0.3;
}
.header__box-mess {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.header__social-link {
  margin: 0 5px;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  transition: opacity linear 1s;
}
.header__social-link:nth-of-type(1) {
  display: none;
  background-image: url(../../images/icon/phone.svg);
}
.header__social-link:nth-of-type(2) {
  background-image: url(../../images/icon/whatsapp.svg);
}
.header__social-link:nth-of-type(3) {
  background-image: url(../../images/icon/viber.svg);
}
.header__social-link:nth-of-type(4) {
  background-image: url(../../images/icon/telega.svg);
}
.header__social-link:nth-of-type(2):hover {
  opacity: 0.5;
}
.header__social-link:nth-of-type(3):hover {
  opacity: 0.5;
}
.header__social-link:nth-of-type(4):hover {
  opacity: 0.5;
}
.header__button-menu {
  display: none;
  background-image: url(../../images/icon/menu.svg);
  width: 25px;
  height: 18px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 0 5px;
  border: none;
  -webkit-border: none;
  -moz-border: none;
  -o-border: none;
  z-index: 2;
}
.header__menu-box {
  border-bottom: 1px solid #e7e7e7;
  -webkit-border-bottom: 1px solid #e7e7e7;
  -moz-border-bottom: 1px solid #e7e7e7;
  -o-border-bottom: 1px solid #e7e7e7;
}
.header__menu {
  padding: 15px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  max-width: 1159px;
  margin: 0 auto;
}
.header__link {
  font-family: "GothamPro";
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 0.8;
  color: #3d4662;
  cursor: pointer;
  text-decoration: none;
  transition: opacity linear 1s;
}
.header__link:hover {
  opacity: 0.3;
}
.header__link_active {
  opacity: 0.3;
}
.header__button-menu_close {
  background: none;
  width: 15px;
  height: 15px;
  top: 26px;
  right: 17px;
  background-image: url(../../images/icon/close.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0);
  background-size: contain;
  border: none;
  -webkit-border: none;
  -moz-border: none;
  -o-border: none;
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
  -o-outline: none;
  cursor: pointer;
  position: absolute;
  padding: 0;
  display: none;
}
@keyframes phone-animation {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(-30deg);
  }
  30% {
    transform: rotate(20deg);
  }
  40%,
  100% {
    transform: rotate(0deg);
  }
}
@media screen and (max-width: 1112px) {
  .header__logo {
    width: 179px;
    height: 36px;
    margin: 0 15px 0 0;
  }
  .header__logo-text {
    max-width: 130px;
  }
  .header__adress {
    max-width: 158px;
  }
  .header__social-link {
    margin: 0 5px;
    width: 32px;
    height: 32px;
  }
}
@media screen and (max-width: 929px) {
  .header__logo-text {
    display: none;
  }
}
@media screen and (max-width: 799px) {
  .header__logo {
    width: 98px;
    height: 20px;
    margin: 0;
  }
  .header__adress {
    font-size: 11px;
  }
  .header__adress::before {
    content: "";
    display: block;
    min-width: 20px;
    height: 25px;
    background-image: url(../../images/icon/map.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 5px 0 0;
  }
  .header__box-mess {
    display: none;
  }
  .header__text {
    max-width: 110px;
    font-size: 10px;
    margin: 0 0 5px 0;
  }
  .header__tel {
    font-size: 15px;
  }
  .header__tel-button {
    height: 28px;
    font-size: 11px;
  }
}
@media screen and (max-width: 768px) {
  .header {
    padding: 5px 24px 0 24px;
  }
  .header__logo {
    width: 98px;
    height: 20px;
    margin: 0;
  }
  .header__mail {
    display: none;
  }
  .header__adress {
    font-size: 11px;
  }
  .header__adress::before {
    content: "";
    display: block;
    min-width: 20px;
    height: 25px;
    background-image: url(../../images/icon/map.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 5px 0 0;
  }
  .header__link {
    max-width: 61px;
    font-size: 11px;
  }
  .header__tel {
    font-size: 25px;
  }
  .header__tel-button {
    height: 28px;
    font-size: 11px;
  }
}
@media screen and (max-device-width: 768px) {
  .header {
    padding: 19px 0;
    border: none;
    -webkit-border: none;
    -moz-border: none;
    -o-border: none;
  }
  .header__box {
    max-width: 430px;
  }
  .header__menu-box {
    border: none;
    -webkit-border: none;
    -moz-border: none;
    -o-border: none;
  }
  .header__logo {
    width: 145px;
    height: 29.14px;
    margin: 0;
  }
  .header__menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    background-color: rgba(61, 70, 98, 0.9);
    height: 100vh;
    top: 0;
    left: -769px;
    padding: 50px 11px 10px 0;
    opacity: 0;
    z-index: 4;
    transition: all linear 0.5s;
  }
  .header__menu_mob_active {
    top: 0;
    left: 0;
    opacity: 1;
    transition: all linear 0.5s;
  }
  .header__button-menu {
    display: block;
  }
  .header__button-menu_close {
    display: block;
  }
  .header__adress {
    display: none;
  }
  .header__logo-box {
    display: none;
  }
  .header__menu .header__link {
    max-width: 320px;
    font-size: 20px;
    margin: 5px 0 5px 24px;
  }
  .header__link {
    color: #ffffff;
  }
}
@media screen and (max-device-width: 420px) {
  .header__menu {
    width: 100vw;
  }
  .header__raiting-link {
    display: none;
  }
}
