.article-text {
  padding: 185px 24px 0 24px;
  max-width: 1156px;
  margin: 0 auto;
}
.article-text__main-title {
  max-width: 1159px;
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 1.5;
  color: #3d4662;
  text-align: left;
  margin: 30px auto;
}
.article-text__author-data {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.article-text__author-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.article-text__author-foto {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  margin: 0 15px 0 0;
}
.article-text__author {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.5;
  color: #3d4662;
  text-align: left;
  margin: 0 0 5px 0;
}
.article-text__author-job {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
  color: #3d4662;
  text-align: left;
  margin: 0;
}
.article-text__data {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
  color: #3d4662;
  text-align: left;
  margin: 5px 0;
}
.article-text__picture {
  margin: 0 auto;
  display: flex;
}
.article-text__main-img {
  width: 700px;
  height: 500px;
  margin: 30px auto 0 auto;
  object-fit: cover;
  border-radius: 15px;
}
.article-text__text-img {
  max-width: 700px;
  font-family: "GothamPro";
  font-style: italic;
  font-weight: 300;
  font-size: 15px;
  line-height: 1.5;
  color: #3d4662;
  text-align: left;
  margin: 10px auto 50px auto;
}
.article-text__source {
  max-width: 700px;
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 1.5;
  color: #3d4662;
  text-align: left;
  margin: 0 auto;
}
.article-text__text {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 1.5;
  color: #3d4662;
  text-align: justify;
  margin: 0 0 30px 0;
}
.article-text__title {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 1.5;
  color: #3d4662;
  text-align: justify;
  margin: 30px 0;
}
.article-text__main-subtitle {
  font-family: "GothamPro";
  font-style: italic;
  font-weight: 300;
  font-size: 25px;
  line-height: 1.5;
  color: #3d4662;
  text-align: justify;
  margin: 0 0 30px 0;
}
.article-text__subtitle {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 1.5;
  color: #3d4662;
  text-align: justify;
  margin: 0 0 30px 0;
}
.article-text__list {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 1.5;
  color: #3d4662;
  text-align: justify;
  margin: 0 0 30px 0;
}
.article-text__reference-box {
  padding: 20px;
  border-radius: 10px;
  margin: 10px 0 30px 0;
  background: linear-gradient(
      270deg,
      rgba(51, 146, 233, 0.5) 0%,
      rgba(111, 204, 245, 0.5) 88.7%
    ),
    #6fccf5;
}
.article-text__reference {
  font-family: "GothamPro";
  font-style: italic;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.5;
  color: #ffffff;
  text-align: left;
  margin: 0 0 5px 0;
}
@media screen and (max-width: 1219px) {
  .article-text {
    max-width: 960px;
  }
}
@media screen and (max-width: 1024px) {
  .article-text__text {
    font-size: 15px;
  }
  .article-text__title {
    font-size: 20px;
  }
  .article-text__list {
    font-size: 15px;
  }
  .article-text__reference {
    font-size: 15px;
  }
  .article-text__main-title {
    font-size: 18px;
  }
  .article-text__main-subtitle {
    font-size: 18px;
  }
  .article-text__subtitle {
    font-size: 18px;
  }
  .article-text__author-job {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .article-text {
    padding: 150px 24px 0 24px;
    max-width: 500px;
  }
  .article-text__main-img {
    width: 500px;
    height: 300px;
  }
  .article-text__text-img {
    max-width: 500px;
  }
  .article-text__source {
    max-width: 500px;
  }
}
@media screen and (max-width: 555px) {
  .article-text {
    max-width: 377px;
  }
  .article-text__main-img {
    width: 377px;
    height: 200px;
  }
  .article-text__text-img {
    max-width: 377px;
  }
  .article-text__source {
    max-width: 377px;
  }
}
@media screen and (max-width: 425px) {
  .article-text {
    padding: 150px 0 0 0;
  }
  .article-text__main-img {
    width: 100%;
    height: 193px;
  }
  .article-text__text-img {
    max-width: 272px;
  }
  .article-text__source {
    max-width: 272px;
  }
  .article-text__text {
    margin: 0 24px 30px 24px;
  }
  .article-text__reference-box {
    margin: 10px 24px 30px 24px;
  }
  .article-text__main-subtitle {
    margin: 0 24px 30px 24px;
  }
  .article-text__main-title {
    margin: 30px 24px 30px 24px;
  }
  .article-text__title {
    margin: 30px 24px 30px 24px;
  }
  .article-text__subtitle {
    margin: 0 24px 30px 24px;
  }
  .article-text__list {
    margin: 0 24px 30px 24px;
  }
  .article-text__author-container {
    margin: 0 24px;
  }
  .article-text__main-img {
    margin: 30px 24px 0 24px;
  }
}
