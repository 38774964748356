.faq {
  max-width: 1160px;
  padding: 100px 24px 0 24px;
  margin: 0 auto;
}
.faq__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}
.faq__box {
  position: relative;
  margin: 0 40px 40px 0;
  width: 560px;
  background-color: #fff;
  cursor: pointer;
  border-bottom: 1px solid #efefef;
  -webkit-border-bottom: 1px solid #efefef;
  -moz-border-bottom: 1px solid #efefef;
  -o-border-bottom: 1px solid #efefef;
}
.faq__box:nth-of-type(2n) {
  margin: 0 0 40px 0;
}
.faq__question {
  min-height: 54px;
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5;
  color: #3d4662;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}
.faq__question::after {
  content: "";
  display: block;
  min-width: 20px;
  min-height: 20px;
  margin: 0 0 0 10px;
  background-image: url(../../images/icon/faq.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.7s ease 0s;
}
.faq__question_open::after {
  transform: rotate(-45deg);
}
.faq__answer {
  opacity: 0;
  max-height: 0;
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: #3d4662;
  transition: max-height 0.7s ease 0s, opacity 0.7s ease 0s;
}
.faq__answer_open {
  max-height: 389px;
  opacity: 1;
}
.faq__form-box {
  position: relative;
  z-index: 2;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #efefef;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -webkit-border: 1px solid #efefef;
  -moz-border: 1px solid #efefef;
  -o-border: 1px solid #efefef;
}
.faq__form-box-title {
  width: 231px;
  height: 205px;
  background-image: url(../../images/faq1.png);
  background-image: image-set(
    url(../../images/faq1.png) type("image/png") 1x,
    url(../../images/faq1load.png) type("image/png") 2x,
    url(../../images/faq1.avif) type("image/avif") 1x,
    url(../../images/faq1load.avif) type("image/avif") 2x,
    url(../../images/faq1.webp) type("image/webp") 1x,
    url(../../images/faq1load.webp) type("image/webp") 2x
  );
  background-image: -webkit-image-set(
    url(../../images/faq1.png) 1x,
    url(../../images/faq1load.png) 2x,
    url(../../images/faq1.avif) 1x,
    url(../../images/faq1load.avif) 2x,
    url(../../images/faq1.webp) 1x,
    url(../../images/faq1load.webp) 2x
  );
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  border-radius: 10px;
  box-shadow: 0px 0px 100px rgba(61, 70, 98, 0.2);
  -webkit-box-shadow: 0px 0px 100px rgba(61, 70, 98, 0.2);
  -moz-box-shadow: 0px 0px 100px rgba(61, 70, 98, 0.2);
  margin: 0 10px 0 0;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
}
.faq__title {
  max-width: 145px;
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 1.5;
  text-align: center;
  color: #ffffff;
  margin: auto;
}
.faq__form-box-subtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 898px;
  height: 205px;
  background-image: url(../../images/faq2.png);
  background-image: image-set(
    url(../../images/faq2.png) type("image/png") 1x,
    url(../../images/faq2load.png) type("image/png") 2x,
    url(../../images/faq2.avif) type("image/avif") 1x,
    url(../../images/faq2load.avif) type("image/avif") 2x,
    url(../../images/faq2.webp) type("image/webp") 1x,
    url(../../images/faq2load.webp) type("image/webp") 2x
  );
  background-image: -webkit-image-set(
    url(../../images/faq2.png) 1x,
    url(../../images/faq2load.png) 2x,
    url(../../images/faq2.avif) 1x,
    url(../../images/faq2load.avif) 2x,
    url(../../images/faq2.webp) 1x,
    url(../../images/faq2load.webp) 2x
  );
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  box-shadow: 0px 0px 100px rgba(61, 70, 98, 0.2);
  -webkit-box-shadow: 0px 0px 100px rgba(61, 70, 98, 0.2);
  -moz-box-shadow: 0px 0px 100px rgba(61, 70, 98, 0.2);
}
.faq__subtitle {
  max-width: 260px;
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #3d4662;
  opacity: 0.6;
  text-align: left;
  margin: 0 39px 0 0;
}
.faq__title-subtitle {
  display: none;
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 1.5;
  text-align: left;
  color: #3d4662;
  margin: auto;
}
.faq__subtitle_span {
  font-weight: 500;
}
.faq__button {
  width: 501px;
  height: 80px;
  font-family: "GothamPro";
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.286;
  color: #ffffff;
  margin: 0;
  text-align: left;
  background: linear-gradient(
      270deg,
      rgba(51, 146, 233, 0.5) 0%,
      rgba(111, 204, 245, 0.5) 88.7%
    ),
    #6fccf5;
  border: none;
  border-radius: 10px;
  -webkit-border: none;
  -moz-border: none;
  -o-border: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
  -o-outline: none;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  transition: opacity linear 1s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.faq__button:hover {
  opacity: 0.5;
}
.faq__button_article {
  width: 203px;
}
.faq__form-box-title_article {
  height: 122px;
}
.faq__form-box-subtitle_article {
  height: 122px;
}
@media screen and (max-width: 1207px) {
  .faq {
    max-width: 920px;
  }
  .faq__form-box {
    max-width: 898px;
    margin: 0 auto;
  }
  .faq__form-box-title {
    display: none;
  }
  .faq__title-subtitle {
    display: block;
  }
  .faq__box {
    margin: 0 0 40px 0;
  }
}
@media screen and (max-width: 968px) {
  .faq__form-box-subtitle {
    height: 280px;
    flex-direction: column;
    background-position: 92%;
    background-size: cover;
  }
  .faq__subtitle {
    text-align: center;
    margin: 0 0 39px 0;
  }
  .faq__title-subtitle {
    text-align: center;
    margin: 39px 0 0 0;
  }
  .faq__button {
    margin: 0 0 39px 0;
  }
}
@media screen and (max-width: 632px) {
  .faq__question {
    font-size: 14px;
  }
  .faq__subtitle {
    font-size: 14px;
  }
  .faq__button {
    width: 233px;
    height: 60px;
    font-size: 12px;
  }
}
